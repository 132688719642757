<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-28 10:18:51
 * @ Description: Fixer's home page in the admin dashboard section of the app.
 -->

<template>
    <div
        style="padding-bottom: 84px;"
    >
        <v-row
            justify="center"
            class="px-3"
        >
            <!-- Desktop -->
            <v-col
                v-if="$vuetify.breakpoint.mdAndUp"
                :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
                cols="12"
            >
                <v-row>
                    <v-col
                        cols="12"
                        class="os-22-r"
                        style="line-height: 2;"
                    >
                        Welcome, {{ name }}!
                    </v-col>
                </v-row>
            </v-col>
            <!-- Desktop -->

            <!-- Mobile -->
            <v-col
                v-if="$vuetify.breakpoint.smAndDown"
                cols="12"
            >
                <v-row>
                    <v-col
                        cols="12"
                        class="os-22-r text-center px-1"
                        style="line-height: 2;"
                    >
                        Welcome, {{ name }}!
                    </v-col>
                </v-row>
            </v-col>
            <!-- Mobile -->
        </v-row>
    </div>
</template>
<script>
    import { mdiAccountMultipleOutline, mdiAccountOutline, mdiDomain, mdiCashMultiple, mdiCrown } from '@mdi/js'

    export default {
        name: 'AdminHomePage',

        computed: {
            name () {
                if (this.$store.state.user.userContext === 'company') return this.$store.state.user.userData.company_name
                else return `${this.$store.state.user.userData.first_name} ${this.$store.state.user.userData.last_name}`
            }
        }
    }
</script>
<style>
    @import url('../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
